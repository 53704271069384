<template>
	<v-container fluid style="max-width: 1200px">
		<div class="mx-2 my-3">
			<span class="text-h3">{{ $t('courses.myCourses') }}</span>
		</div>

		<v-divider class="my-2" />

		<v-row>
			<v-col cols="12" lg="8" md="12">
				<v-subheader>
					{{ $t('settings.numberOfCourses') }}: {{ courses.length }}/{{ currentUser.limits ? currentUser.limits.courses : '???' }}
				</v-subheader>
			</v-col>
			<v-col cols="12" lg="4" md="12">
				<v-btn color="info" rounded :to="{ name: 'CourseAdd' }" class="float-right">
					{{ courses.length ? $t('courses.addMoreCourses') : $t('courses.addCourses') }}
				</v-btn>
			</v-col>
		</v-row>

		<v-fade-transition group hide-on-leave>
			<template v-if="loading">
				<v-row key="loadingResults" no-gutters>
					<v-col cols="12" lg="4" md="6" sm="12" v-for="i in 12" :key="`loadingResult${i}`" class="px-1">
						<v-skeleton-loader type="card" class="my-2 mx-auto flex-grow-1 rounded-xl" max-width="1200" />
					</v-col>
				</v-row>
			</template>

			<template v-else-if="courses.length">
				<v-row key="filteresResults" no-gutters>
					<v-col cols="12" lg="4" md="6" sm="12" v-for="course in courses" :key="`course${course.id}`" class="px-1 d-flex flex-column">
						<MyCourseCard :key="course.id" :course="course" />
					</v-col>
				</v-row>
			</template>

			<template v-else>
				<div key="noItemsData" class="mx-auto">
					<div class="mt-2 mb-5 text-center">
						<v-icon size="128" role="img"> mdi-package-variant </v-icon>
						<br />
						<span class="text-h6 font-weight-bold">{{ $t('search.empty') }}</span>
					</div>
					<h4 class="m-4 text-center">
						{{ $t('courses.myCoursesHelp') }}
					</h4>
				</div>
			</template>
		</v-fade-transition>
	</v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	metaInfo() {
		return {
			title: this.$t('courses.myCourses'),
		}
	},
	components: {
		MyCourseCard: () => import('@/components/courses/MyCourseCard.vue'),
	},
	data() {
		return {
			loading: true,
		}
	},
	computed: {
		...mapGetters({
			courses: 'courses/courses',
			currentUser: 'user/currentUser',
		}),
	},
	created() {
		this.fetchGDriveEmails()
		this.fetchCourses().then(() => {
			this.loading = false
		})
	},
	methods: {
		...mapActions('courses', ['fetchCourses']),
		...mapActions('gdriveemails', ['fetchGDriveEmails']),
	},
}
</script>

<style scoped></style>
